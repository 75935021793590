import React from "react";
import { NavLink } from "react-router-dom";
const UcBoyutluBaski = () => {
  return (
    <div className="container mx-auto flex flex-col items-center justify-center mt-10">
      <div id="serviceHead" className="flex max-w-[1000px]">
        <div
          id="serviceHeadInfo"
          className="max-w-[700px] flex flex-col items-center text-center justify-center p-5 md:p-0"
        >
          <h1 id="servideTitle" className="text-xl font-bold">
            3D Baskı
          </h1>
          <p>
            Biz, 3D baskı alanındaki bu heyecan verici gelişmelerle, paydaşlara
            sadece teknik beceriler kazandırmakla kalmıyor, aynı zamanda
            yaratıcılıklarını ve problem çözme yeteneklerini geliştirmelerine
            olanak tanıyoruz. Siz de bu ileri teknoloji yolculuğumuza katılın ve
            geleceğin tasarımcıları arasında yer alın!
          </p>
          <NavLink
            className={"mt-5 bg-sky-700 px-2 py-1 rounded text-white"}
            to="/contact"
          >
            İletişime Geç
          </NavLink>
        </div>
        <div id="serviceImage" className="hidden lg:block">
          <img
            src="https://apiss.mithatsarsu.com/img/3d-printer-icon-style-clip-art-59138-bff2459d-18c5-4c79-85fe-b5b73e2410d3.jpg"
            alt="webtasarim"
          />
        </div>
      </div>
      <div id="serviceBody">
        <div id="servicePresentation">
          <div
            id="servicePresentationHead"
            className="text-center bg-blue-900 py-2 md:rounded text-white text-lg font-semibold mt-10 mb-10"
          >
            <h1>IPSS İnovasyonun Sınırlarını 3D Baskı İle Zorluyor!</h1>
          </div>
          <div
            id="servicePresentationBody"
            className="flex flex-col md:flex-row p-5 md:p-0 gap-5 mb-5"
          >
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center gap-2">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/3d-printer-92b00394-edea-445c-9cb5-f41f970f43d4.png"
                  alt="detail"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">Hızlı Prototip Üretimi</h1>
                <p>
                  3D baskı, tasarım sürecini hızlandırarak prototiplerin hızlı
                  bir şekilde üretilmesini sağlar. IPSS yaratıcılıklarını
                  serbest bırakmalarına olanak tanır.
                </p>
              </div>
            </div>
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/3d-modeling-b28182e3-d37e-4015-95a5-3bbc47feb5f6.png"
                  alt="engineer"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">Özelleştirilebilirlik</h1>
                <p>
                  IPSS her projenin özel ihtiyaçlarına uygun olarak
                  tasarlanabilen 3D baskı ile çözüm odaklı yaklaşmamıza imkan
                  tanır.
                </p>
              </div>
            </div>
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/3d-design-b2c0f66f-ab08-43a1-b49e-acff5de2c335.png"
                  alt="engineer"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  İleri Teknolojiyle Eğitim
                </h1>
                <p>
                  3D baskı, ileri teknolojiyle tanışmalarını sağlar. Bu
                  teknolojiyle çalışmak IPSS paydaşlarının sektöre daha hazır ve
                  donanımlı bir şekilde girmelerine yardımcı olur.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="serviceProjects" className="mb-20">
          <div id="projectPresentation">
            <div
              id="projectPresentationHead"
              className="text-center bg-blue-900 py-2 rounded text-white text-lg font-semibold mt-10 mb-10"
            >
              <h1 id="projectPresentationTitle">IPSS'in Yaptığı 3D Baskılar</h1>
            </div>
            <div
              id="projectPresentationBody"
              className="flex gap-16 p-5 md:p-0"
            >
              <div
                id="projectPresentationsImage"
                className="w-full hidden md:block"
              >
                <img
                  src="https://apiss.mithatsarsu.com/img/undraw_pen_tool_re_s92o-7158a2e8-3d27-424b-8202-2ae3181cc614.svg"
                  alt="project"
                />
              </div>
              <div
                id="projectPresentationContents"
                className="flex flex-col gap-6"
              >
                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                  <div className="servicePresentationItemImage hidden md:block w-1/3">
                    <img
                      className="w-20 h-20"
                      src="https://apiss.mithatsarsu.com/img/mockup-d7d582c0-1d06-4440-baf1-1e21b3ace821.png"
                      alt="engineer"
                    />
                  </div>
                  <div className="servicePresentationItemContent">
                    <h1 className="text-lg font-medium">
                      Prototipler ve Ürün Geliştirme
                    </h1>
                    <p>
                      3D baskı, ürün prototiplerinin hızlı ve maliyet etkin bir
                      şekilde üretilmesini sağlar. Tasarımcılar, mühendisler ve
                      üreticiler, fikirlerini test etmek ve iteratif olarak
                      iyileştirmek için 3D baskıyı kullanabilirler.
                    </p>
                  </div>
                </div>
                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                  <div className="servicePresentationItemImage hidden md:block w-1/3">
                    <img
                      className="w-20 h-20"
                      src="https://apiss.mithatsarsu.com/img/3d-design%20(1)-b5589d89-ec7f-4bdb-83cf-35110286ed37.png"
                      alt="engineer"
                    />
                  </div>
                  <div className="servicePresentationItemContent">
                    <h1 className="text-lg font-medium">
                      Kişiselleştirilmiş Ürünler
                    </h1>
                    <p>
                      Müşteri taleplerine göre kişiselleştirilmiş ürünlerin
                      üretimi için 3D baskı kullanılabilir. Örneğin, takılar,
                      telefon kılıfları, anahtarlık gibi küçük aksesuarlar veya
                      kişiselleştirilmiş oyuncaklar üretilebilir.
                    </p>
                  </div>
                </div>
                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                  <div className="servicePresentationItemImage hidden md:block w-1/3">
                    <img
                      className="w-20 h-20"
                      src="https://apiss.mithatsarsu.com/img/graphic-designer-325d7a85-74c9-4b71-b6e2-3a26dc1ac582.png"
                      alt="engineer"
                    />
                  </div>
                  <div className="servicePresentationItemContent">
                    <h1 className="text-lg font-medium">Sanat ve Heykel</h1>
                    <p>
                      Sanatçılar, 3D baskı teknolojisini kullanarak benzersiz
                      heykeller ve sanat eserleri yaratabilirler. Geleneksel
                      sanat formlarıyla birleştirilerek yeni ve yenilikçi sanat
                      eserleri üretilebilir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UcBoyutluBaski;
