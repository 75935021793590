import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Blog from "../components/Home/Blog";
import Home from "../pages/Home";
import Sss from "../pages/Sss";
import Contact from "../pages/Contact";
import PostDetail from "../pages/PostDetail";
import ServiceDetail from "../pages/ServiceDetail";
import ApplicationForm from "../pages/ApplicationForm";
import TraineeForm from "../pages/TraineeForm";
import NotFound from "../pages/NotFound";
import Redirect from "./Redirect";

//servisler
import TeknikCizim from "../services/TeknikCizim";
import MobilUygulama from "../services/MobilUygulama";
import KurumsalYazilim from "../services/KurumsalYazilim";
import WebTasarim from "../services/WebTasarim";
import UcBoyutluBaski from "../services/UcBoyutluBaski";
import SosyalMedya from "../services/SosyalMedya";
import Goruntu from "../services/Goruntu";
import Otonom from "../services/Otonom";

const AppRouter = () => {
  const user = useSelector((state) => state.user.user);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sss" element={<Sss />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/p/:slugUrl" element={<PostDetail />} />
      <Route path="/s/teknik-cizim" element={<TeknikCizim />} />
      <Route path="/s/mobil-uygulama" element={<MobilUygulama />} />
      <Route path="/s/kurumsal-yazilim" element={<KurumsalYazilim />} />
      <Route path="/s/web-tasarim" element={<WebTasarim />} />
      <Route path="/s/3d-baski" element={<UcBoyutluBaski />} />
      <Route path="/s/sosyal-medya-yonetimi" element={<SosyalMedya />} />
      <Route path="/s/goruntu-ve-ses-isleme" element={<Goruntu />} />
      <Route path="/s/robotik-kodlama" element={<Otonom />} />
      {/* <Route path="/s/:slugUrl" element={<ServiceDetail />} /> */}
      <Route path="/team-application" element={<ApplicationForm />} />
      <Route path="/trainee-application" element={<TraineeForm />} />
      <Route path="/admin" element={<Redirect />} />
      <Route path="/login" element={<Redirect />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
