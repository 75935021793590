import React from 'react'

const NotFound = () => {
  return (
    <div className='text-center mt-10 mb-96 flex justify-center items-center'>
        <h1 className='text-3xl'>Böyle bir sayfa bulunmuyor...</h1>
    </div>
  )
}

export default NotFound