import React from "react";
import { NavLink } from "react-router-dom";
const WebTasarim = () => {
  return (
    <div className="container mx-auto flex flex-col items-center justify-center mt-10">
      <div id="serviceHead" className="flex max-w-[1000px]">
        <div
          id="serviceHeadInfo"
          className="max-w-[700px] flex flex-col items-center text-center justify-center p-5 md:p-0"
        >
          <h1 id="servideTitle" className="text-xl font-bold">
            Web Tasarım
          </h1>
          <p>
            Dijital dünyada markanızın çevrimiçi kimliğini oluşturacak kullanıcı
            dostu ve estetik web siteleri tasarlıyoruz. Çağımızın
            gerekliliklerine uygun, SEO optimizasyonlu, mobil uyumlu ve hızlı
            yüklenen web siteleriyle işletmenizin online varlığını
            güçlendiriyoruz. Kullanıcı deneyimini ön planda tutarak, yaratıcı ve
            yenilikçi tasarımlarımızla ziyaretçilerinizi etkilemeyi ve dönüşüm
            oranlarınızı artırmayı hedefliyoruz.
          </p>
          <NavLink
            className={"mt-5 bg-sky-700 px-2 py-1 rounded text-white"}
            to="/contact"
          >
            İletişime Geç
          </NavLink>
        </div>
        <div id="serviceImage" className="hidden lg:block">
          <img
            src="https://apiss.mithatsarsu.com/img/responsive-design-09ad69b4-72c1-41e5-8ad1-ceabe6541827.png"
            alt="webtasarim"
          />
        </div>
      </div>
      <div id="serviceBody">
        <div id="servicePresentation">
          <div
            id="servicePresentationHead"
            className="text-center bg-blue-900 py-2 md:rounded text-white text-lg font-semibold mt-10 mb-10"
          >
            <h1>Web Tasarımda Neden IPSS?</h1>
          </div>
          <div
            id="servicePresentationBody"
            className="flex flex-col md:flex-row p-5 md:p-0 gap-5 mb-5"
          >
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center gap-2">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/adjustment-1d00e96a-a24b-48f4-af37-83718d8aa391.png"
                  alt="detail"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  Estetik ve Fonksiyonu Birleştirme
                </h1>
                <p>
                  Projelerimizde estetik tasarımı, kullanıcı dostu ve işlevsel
                  bir deneyimle birleştiriyoruz. Web tasarımı, teknolojiyi
                  kullanıcılarınıza daha yakın ve etkili bir şekilde
                  iletebilmenin anahtarıdır.
                </p>
              </div>
            </div>
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/standard%20(1)-b7322ba2-93b7-4d19-863c-484ee8c36ea0.png"
                  alt="engineer"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  Sektör Standartlarına Uyum
                </h1>
                <p>
                  Enstitümüz, web tasarım projelerinde sektör standartlarına
                  titizlikle uyar ve bu sayede kullanıcıların güncel ve
                  rekabetçi bir çevrede bulunmalarını sağlar.
                </p>
              </div>
            </div>
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/project-management-bf6d01db-837d-46f7-8313-dd9d0f80ad26.png"
                  alt="engineer"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">Sürekli İnovasyon</h1>
                <p>
                  Teknolojideki hızlı değişimlere ayak uydurarak, projelerimizde
                  sürekli olarak yenilikçi tasarım ve geliştirme yaklaşımlarını
                  benimsemekteyiz. Böylece, müşterilerimize her zaman en iyi
                  çözümleri sunabiliyoruz.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="serviceProjects" className="mb-20">
          <div id="projectPresentation">
            <div
              id="projectPresentationHead"
              className="text-center bg-blue-900 py-2 rounded text-white text-lg font-semibold mt-10 mb-10"
            >
              <h1 id="projectPresentationTitle">
                IPSS'in Geliştirdiği Web Uygulamaları
              </h1>
            </div>
            <div
              id="projectPresentationBody"
              className="flex gap-16 p-5 md:p-0"
            >
              <div
                id="projectPresentationsImage"
                className="w-full hidden md:block"
              >
                <img
                  src="https://apiss.mithatsarsu.com/img/undraw_software_engineer_re_tnjc-533a2518-ad96-475e-ab1c-cb5b4e584da5.svg"
                  alt="project"
                />
              </div>
              <div
                id="projectPresentationContents"
                className="flex flex-col gap-6"
              >
                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                  <div className="servicePresentationItemImage hidden md:block w-1/3">
                    <img
                      className="w-20 h-20"
                      src="https://apiss.mithatsarsu.com/img/blogging-50f69e32-326a-4e64-bb05-afaa4daddd7e.png"
                      alt="engineer"
                    />
                  </div>
                  <div className="servicePresentationItemContent">
                    <h1 className="text-lg font-medium">
                      Kişisel Portfolio ve Blog Siteleri
                    </h1>
                    <p>
                      IPSS, kişisel portföy ve blog sitelerinizi özenle
                      tasarlayarak iş hayatınızda öne çıkmanıza yardımcı olur.
                      Profesyonelce oluşturulan siteler, potansiyel işverenler
                      veya müşteriler üzerinde kalıcı bir izlenim bırakırken,
                      kişisel markanızı güçlendirir ve rekabet avantajınızı
                      artırır. Başarıya giden yolda IPSS ile adım atın ve
                      dijital varlığınızı güçlendirin.
                    </p>
                  </div>
                </div>
                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                  <div className="servicePresentationItemImage hidden md:block w-1/3">
                    <img
                      className="w-20 h-20"
                      src="https://apiss.mithatsarsu.com/img/revenue-f3ba5807-aef0-403b-94b8-346855d84300.png"
                      alt="engineer"
                    />
                  </div>
                  <div className="servicePresentationItemContent">
                    <h1 className="text-lg font-medium">
                      Şirket ve Ürün Tanıtım Siteleri
                    </h1>
                    <p>
                      IPSS, şirketinizin tanıtımı ve reklamı için özenle
                      çalışarak müşterilerinizi memnun edecek web siteleri
                      tasarlar. Sunduğumuz özelleştirilmiş çözümlerle markanızı
                      güçlendirir ve dijital varlığınızı etkili bir şekilde
                      yönetmenizi sağlarız. Profesyonelce oluşturulan siteler,
                      markanızın güvenilirliğini artırır ve işinizi öne çıkarır.
                      İşinizi geliştirmek için IPSS ile çalışın ve dijital
                      varlığınızı güçlendirin.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebTasarim;
