import React from "react";
import { NavLink } from "react-router-dom";
const KurumsalYazilim = () => {
  return (
    <div className="container mx-auto flex flex-col items-center justify-center mt-10">
      <div id="serviceHead" className="flex max-w-[1000px]">
        <div
          id="serviceHeadInfo"
          className="max-w-[700px] flex flex-col items-center text-center justify-center p-5 md:p-0"
        >
          <h1 id="servideTitle" className="text-xl font-bold">
            Kurumsal Yazılım
          </h1>
          <p>
            Biz, kurumsal yazılım projelerinde iş süreçlerini optimize etme ve
            müşterilerimize özel çözümler sunma konusunda uzmanlaşmış bir
            ekibiz. Sizi de bu inovatif ve teknolojiyle dolu yolculukta bizimle
            birlikte olmaya davet ediyoruz!
          </p>
          <NavLink
            className={"mt-5 bg-sky-700 px-2 py-1 rounded text-white"}
            to="/contact"
          >
            İletişime Geç
          </NavLink>
        </div>
        <div id="serviceImage" className="hidden lg:block">
          <img
            src="https://apiss.mithatsarsu.com/img/office-building-08fdaac9-ac77-4548-ae6f-0e11903c35f2.png"
            alt="kurumsalyazilim"
          />
        </div>
      </div>
      <div id="serviceBody">
        <div id="servicePresentation">
          <div
            id="servicePresentationHead"
            className="text-center bg-blue-900 py-2 md:rounded text-white text-lg font-semibold mt-10 mb-10"
          >
            <h1>Neden IPSS Kurumsal Yazılım?</h1>
          </div>
          <div
            id="servicePresentationBody"
            className="flex flex-col md:flex-row p-5 md:p-0 gap-5 mb-5"
          >
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center gap-2">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/system-2ef47583-9b91-481a-9e2c-b50732609fdd.png"
                  alt="detail"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  Özelleştirilmiş İş Çözümleri
                </h1>
                <p>
                  Projelerimiz, IPSS müşterilerilerinin ihtiyaçlarına yönelik
                  özelleştirilmiş yazılım çözümleri sunar. İş süreçlerini
                  iyileştirmek ve verimliliği artırmak için geliştirilen bu
                  yazılımlar, kurumların hedeflerine daha etkili bir şekilde
                  ulaşmalarını sağlar.
                </p>
              </div>
            </div>
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/cyber-security-b6492c3f-9c8e-428b-bf77-eb8323bfc570.png"
                  alt="engineer"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  Veri Güvenliği ve Uyumluluk
                </h1>
                <p>
                  IPSS Kurumsal yazılım projelerinde, güçlü veri güvenliği
                  önlemleri ve uyumluluk standartlarına uygunluk büyük önem
                  taşır. Müşterilerimizin verilerini korumak ve yasal
                  gereksinimlere uyum sağlamak için titizlikle çalışırız.
                </p>
              </div>
            </div>
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/customer-service-1a39f35d-d205-4b5b-aff1-592e14927cc1.png"
                  alt="engineer"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  Sürekli İyileştirme ve Destek
                </h1>
                <p>
                  IPSS'de yazılım projelerimiz sadece başlangıçtır; sürekli
                  iyileştirme ve destek süreçleriyle müşterilerimizin iş
                  sürekliliğini ve rekabet avantajını sağlamaya devam ederiz.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="serviceProjects" className="mb-20">
          <div id="projectPresentation">
            <div
              id="projectPresentationHead"
              className="text-center bg-blue-900 py-2 rounded text-white text-lg font-semibold mt-10 mb-10"
            >
              <h1 id="projectPresentationTitle">
                IPSS'in Gerçekleştirdiği Projelerden Bazıları
              </h1>
            </div>
            <div
              id="projectPresentationBody"
              className="flex gap-16 p-5 md:p-0"
            >
              <div
                id="projectPresentationsImage"
                className="w-full hidden md:block"
              >
                <img
                  src="https://apiss.mithatsarsu.com/img/undraw_software_engineer_re_tnjc-533a2518-ad96-475e-ab1c-cb5b4e584da5.svg"
                  alt="project"
                />
              </div>
              <div
                id="projectPresentationContents"
                className="flex flex-col gap-6"
              >
                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                  <div className="servicePresentationItemImage hidden md:block w-1/3">
                    <img
                      className="w-20 h-20"
                      src="https://apiss.mithatsarsu.com/img/teamwork-815e369f-9b8c-4065-b6db-81e68eac00a4.png"
                      alt="engineer"
                    />
                  </div>
                  <div className="servicePresentationItemContent">
                    <h1 className="text-lg font-medium">
                      Personel Yönetim Sistemi Geliştirme
                    </h1>
                    <p>
                      IPSS kurumsal yazılım geliştirme konusundaki uzmanlıkla,
                      bir personel yönetim sisteminin tasarımını ve
                      geliştirmesini üstlenmiştir. Bu sistem, personel
                      verimliliğini artırmak, kaynakları etkili bir şekilde
                      yönetmek ve iş süreçlerini optimize etmek amacıyla
                      tasarlanmıştır.
                    </p>
                  </div>
                </div>
                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                  <div className="servicePresentationItemImage hidden md:block w-1/3">
                    <img
                      className="w-20 h-20"
                      src="https://apiss.mithatsarsu.com/img/relationship-0b0f3e8f-03b6-49b0-a013-bb70f8ba92f1.png"
                      alt="engineer"
                    />
                  </div>
                  <div className="servicePresentationItemContent">
                    <h1 className="text-lg font-medium">
                      CRM (Müşteri İlişkileri Yönetimi) Yazılımı Entegrasyonu
                    </h1>
                    <p>
                      IPSS, bir kuruluş için özelleştirilmiş bir CRM yazılımının
                      entegrasyonu konusunda başarılı projeyi tamamlamıştır. Bu
                      projeler, müşteri ilişkilerini güçlendirmek, satış
                      süreçlerini optimize etmek ve verimliliği artırmak için
                      tasarlanmıştır.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KurumsalYazilim;
