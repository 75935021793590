import React from "react";
import { NavLink } from "react-router-dom";
const TeknikCizim = () => {
  return (
    <div className="container mx-auto flex flex-col items-center justify-center mt-10">
      <div id="serviceHead" className="flex max-w-[1000px]">
        <div
          id="serviceHeadInfo"
          className="max-w-[700px] flex flex-col items-center text-center justify-center p-5 md:p-0"
        >
          <h1 id="servideTitle" className="text-xl font-bold">
            Teknik Çizim
          </h1>
          <p>
            Teknik çizimde estetikle fonksiyonu birleştiren bir ekibiz. Her
            projemizde mükemmelliği hedeflerken, IPSS olarak sektördeki en yeni
            teknik çizim becerilerini kazandırmayı amaçlıyoruz. Siz de bu
            yenilikçi yolculukta bizimle birlikte olun!
          </p>
          <NavLink className={"mt-5 bg-sky-700 px-2 py-1 rounded text-white"} to="/contact">İletişime Geç</NavLink>
        </div>
        <div id="serviceImage" className="hidden lg:block">
          <img
            src="https://apiss.mithatsarsu.com/img/teknikcizim-7cf6e0a3-3814-4724-88a0-e41d31ee1fc5.png"
            alt="teknikcizim"
          />
        </div>
      </div>
      <div id="serviceBody">
        <div id="servicePresentation">
          <div
            id="servicePresentationHead"
            className="text-center bg-blue-900 py-2 md:rounded text-white text-lg font-semibold mt-10 mb-10"
          >
            <h1>Teknik Çizimde Neden IPSS?</h1>
          </div>
          <div id="servicePresentationBody" className="flex flex-col md:flex-row p-5 md:p-0 gap-5 mb-5">
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center gap-2">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/graphic-design-e642c28b-11e0-46ff-baeb-96bcd880ce92.png"
                  alt="detail"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  Hassas ve Detaylı Tasarımlar
                </h1>
                <p>
                  Teknik çizim, projelerimizde detaylara odaklanmamızı ve
                  mükemmelliği hedeflememizi sağlar. Her çizgi, her ölçü ve her
                  detay, projelerimizin kalitesini artırır.
                </p>
              </div>
            </div>
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/engineer-5885fb26-c896-4800-9dde-5c262ac64c8e.png"
                  alt="engineer"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  Gelişmiş Mühendislik Çözümleri
                </h1>
                <p>
                  Teknik çizim, mühendislik projelerimizin temelini oluşturur.
                  Bu sayede, IPSS sektördeki karmaşık sorunları çözebilecekleri
                  gelişmiş mühendislik becerileri geliştirirler.
                </p>
              </div>
            </div>
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/standard-2202d790-f6d6-4747-b83c-78ff239dab45.png"
                  alt="engineer"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  Endüstri Standartlarına Uyum
                </h1>
                <p>
                  Projelerimiz, endüstri standartlarına uygun teknik çizim ve
                  tasarımları içerir. IPSS mezuniyet sonrasında sektöre hızlı ve
                  etkili bir şekilde entegre olmalarını sağlar.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="serviceProjects" className="mb-20">
          <div id="projectPresentation">
            <div
              id="projectPresentationHead"
              className="text-center bg-blue-900 py-2 rounded text-white text-lg font-semibold mt-10 mb-10"
            >
              <h1 id="projectPresentationTitle">
                IPSS'in Yürüttüğü Projelerden Bazıları
              </h1>
            </div>
            <div id="projectPresentationBody" className="flex gap-16 p-5 md:p-0">
              <div id="projectPresentationsImage" className="w-full hidden md:block">
                <img
                  src="https://apiss.mithatsarsu.com/img/undraw_maker_launch_re_rq81-cda81f50-baae-405d-be2a-894be94eb76c.svg"
                  alt="project"
                />
              </div>
              <div id="projectPresentationContents" className="flex flex-col gap-6">
                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                  <div className="servicePresentationItemImage hidden md:block w-1/3">
                    <img
                      className="w-20 h-20"
                      src="https://apiss.mithatsarsu.com/img/assemble-abdee8ad-85f3-4970-b6e6-383a07fcfcfb.png"
                      alt="engineer"
                    />
                  </div>
                  <div className="servicePresentationItemContent">
                    <h1 className="text-lg font-medium">
                      Makine Parça Tasarımı ve Optimizasyonu
                    </h1>
                    <p>
                      IPSS tarafından gerçekleştirilen proje, özel bir makine
                      parçasının tasarımını ve optimize edilmiş üretim
                      süreçlerini içerir. Hassas teknik çizimler ve 3D modelleme
                      ile başlayan bu proje, makine parçasının mükemmel
                      performans ve dayanıklılık sağlamasını hedeflemektedir.
                    </p>
                  </div>
                </div>
                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                  <div className="servicePresentationItemImage hidden md:block w-1/3">
                    <img
                      className="w-20 h-20"
                      src="https://apiss.mithatsarsu.com/img/circuit-board-dee5bd0f-196c-429c-b7b1-6823a63d8e99.png"
                      alt="engineer"
                    />
                  </div>
                  <div className="servicePresentationItemContent">
                    <h1 className="text-lg font-medium">
                      Elektronik Devre Kartı Tasarımı
                    </h1>
                    <p>
                      IPSS elektronik mühendisliği odaklı projede, özel bir
                      elektronik devre kartının tasarımını üstlenmiştir. Devre
                      şematikleri, çizimler ve simülasyonlarla desteklenen bu
                      proje, güçlü bir teknik çizim ve mühendislik yaklaşımını
                      temsil etmektedir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeknikCizim;
