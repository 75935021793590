import React from "react";
import { NavLink } from "react-router-dom";
import SingleProject from "./SingleProject";
import { useSelector } from "react-redux";
const Projects = (props) => {
  const projects = useSelector((state) => state.project.projects);
  return (
    <div id="projects" className="flex flex-col items-center pb-20">
      <div className="bg-blue-800 text-center text-white text-2xl py-3 px-6 w-full font-bold rounded">
        Geliştirdiğimiz Projeler
      </div>
      <div className="comps-group items-center mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 justify-center gap-5">
        {projects?.map((project) => {
          return (
            <NavLink key={project._id} target="_blank" to={project.RedirectUrl}>
              <SingleProject
                img={project.ImageUrl}
                title={project.ProjectTitle}
                desc={project.description}
              />
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
