import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Banner = () => {
  const service = useSelector((state) => state.service);

  const handleNavLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="bg-blue-900 bg-img items-center flex gap-10 flex-col justify-center">
      <div className="flex flex-col-reverse md:flex-row mb-10 md:mt-0 px-5 items-center justify-center">
        <div className="text-white text-center">
          <h1 className="text-2xl mb-3">Institute of Project Support and Solidarity</h1>
          <NavLink to="/contact" className="bg-blue-900 text-white hover:bg-white hover:text-blue-900 duration-200 p-2 rounded">Hizmet Almak İçin Bize Ulaşın</NavLink>
        </div>
        <div><img className="w-96 h-96" src="./ipss.png" alt="" /></div>
      </div>
    </div>
  );
};

export default Banner;
