import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faPhone, faEnvelope, faHouse } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const social = useSelector((state) => state.social);
  const services = useSelector((state) => state.service.services);
  const location = useLocation().pathname;

  const handleNavLinkClick = () => {
    // Sayfa geçişini gerçekleştirirken scroll pozisyonunu sıfırla
    window.scrollTo(0, 0);
  };

  if (
    location === "/login" ||
    location === "/register" ||
    location.startsWith("/admin")
  )
    return null;
  return (
    <div className="bg-gradient py-16 border-t bg-blue-900 text-white">
      <div className="container mx-auto px-32 flex flex-col md:flex-row items-center max-2xl:px-10 md:items-start gap-5 md:gap-0 justify-between">
        <div className="footer-column">
          <div>
            <h4 className="text-xl text-center md:text-2xl lg:text-3xl font-bold tracking-wide mb-4">
              Sosyal Medya
            </h4>
            <div className="footer-icons flex gap-3 items-center justify-center">
              {social?.socials?.map((item) => {
                return (
                  <a
                    key={item._id}
                    href={item?.accountUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      height={56}
                      width={56}
                      className="p-3 hover:scale-110 transition"
                      src={item.iconUrl}
                      alt={item.name}
                      loading="lazy"
                    />
                  </a>
                );
              })}
            </div>
            <div className="text-center mt-10">
              <h4 className="text-xl md:text-2xl lg:text-3xl font-bold tracking-wide mb-4">
                İletişim Bilgileri
              </h4>
              <div className="text-center">
                <p>
                  <FontAwesomeIcon icon={faPhone} /> +90 539 276 77 09{" "}
                </p>
                <p className="mt-2">
                  <FontAwesomeIcon icon={faEnvelope} />{" "} fix@ipssolidarity.com
                </p>
                <p className="mt-2">
                  <FontAwesomeIcon icon={faHouse} />{" "} Sanayi, Teknopark Blv No: 1 D:9A, 34906 Pendik/İstanbul
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-column">
          <h4 className="text-xl md:text-2xl lg:text-3xl font-bold tracking-wide mb-4">
            Kurumsal
          </h4>
          <ul>
            <li className="footer-hover text-lg mb-3 border-b pb-2">
              <NavLink
                to="/sss"
                className="text-gray-300 hover:text-white duration-100"
                onClick={handleNavLinkClick}
              >
                <FontAwesomeIcon icon={faCaretRight} /> Hedeflerimiz
              </NavLink>
            </li>
            <li className="footer-hover text-lg mb-3 border-b pb-2">
              <NavLink
                to="/sss"
                className="text-gray-300 hover:text-white duration-100"
                onClick={handleNavLinkClick}
              >
                <FontAwesomeIcon icon={faCaretRight} /> SSS
              </NavLink>
            </li>
            <li className="footer-hover text-lg mb-3 border-b pb-2">
              <NavLink
                to="/"
                className="text-gray-300 hover:text-white duration-100"
                onClick={handleNavLinkClick}
              >
                <FontAwesomeIcon icon={faCaretRight} /> Blog Yazıları
              </NavLink>
            </li>
            <li className="footer-hover text-lg mb-3 border-b pb-2">
              <NavLink
                to="/contact"
                className="text-gray-300 hover:text-white duration-100"
                onClick={handleNavLinkClick}
              >
                <FontAwesomeIcon icon={faCaretRight} /> İletişim
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h4 className="text-xl md:text-2xl lg:text-3xl font-bold tracking-wide mb-4">
            Hizmetlerimiz
          </h4>
          <ul>
            {services?.map((item) => {
              return (
                <li
                  key={item._id}
                  className="footer-hover mb-3 text-lg border-b pb-2"
                >
                  <NavLink
                    to={`/s/${item.SlugUrl}`}
                    className="text-gray-300 hover:text-white duration-100"
                    onClick={handleNavLinkClick}
                  >
                    <FontAwesomeIcon icon={faCaretRight} /> {item.ServiceTitle}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
