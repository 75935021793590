import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const CarouselTwo = () => {
  const prevArrow = (
    <LeftOutlined style={{ fontSize: "24px", color: "#000" }} />
  );
  const nextArrow = (
    <RightOutlined style={{ fontSize: "24px", color: "#000" }} />
  );

  return (
    <div className="mt-3 mb-3 text-xl text-white">
      <Carousel
        autoplay={{ interval: 3000 }}
        infinite
        className="p-3"
        prevArrow={prevArrow}
        nextArrow={nextArrow}
        dots={{ className: "text-black" }}
      >
        <div className="text-xl pb-10">
          <div className="flex flex-col md:flex-row gap-6 justify-evenly">
            <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
              <div>
                <img
                  className="h-20 w-20 object-contain"
                  src="/assets/home/team.png"
                  alt=""
                />
              </div>
              <div>
                <h1>🌐 Deneyimli Ekibimiz</h1>
                <p>
                  Sizi aşan sınırları kaldıran, teknolojiyi sanatla harmanlayan
                  bir ekibiz.
                </p>
              </div>
            </div>
            <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
              <div>
                <img
                  className="h-20 w-20 object-contain"
                  src="/assets/home/problem.png"
                  alt=""
                />
              </div>
              <div>
                <h1>💡 Problem Çözme Becerisi</h1>
                <p>
                  Karşılaştığımız her zorluğu bir fırsata dönüştürme
                  yeteneğimiz, projelerinizi çığır açan kılacak.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-xl">
          <div className="flex flex-col md:flex-row gap-6 justify-evenly">
            <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
              <div>
                <img
                  className="h-20 w-20 object-contain"
                  src="/assets/home/innovatif.png"
                  alt=""
                />
              </div>
              <div>
                <h1>🚀 İnovatif Yaklaşımımız</h1>
                <p>
                  Teknolojiye olan tutkumuz ve sürekli gelişen bir dünyada öncü
                  olma arzumuz, her zaman bir adım önde olmamızı sağlıyor.
                </p>
              </div>
            </div>
            <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
              <div>
                <img
                  className="h-20 w-20 object-contain"
                  src="/assets/home/costumer.png"
                  alt=""
                />
              </div>
              <div>
                <h1>🤝 Müşteri Odaklılık</h1>
                <p>
                  Sizi anlamak, ihtiyaçlarınızı anında karşılamak ve
                  projelerinizi sizinle birlikte şekillendirmek için buradayız.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default CarouselTwo;
