import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const YourComponent = () => {
  const prevArrow = (
    <LeftOutlined style={{ fontSize: "24px", color: "#000" }} />
  );
  const nextArrow = (
    <RightOutlined style={{ fontSize: "24px", color: "#000" }} />
  );

  return (
    <div className="mt-3 mb-3 text-xl">
      <Carousel
        autoplay={{ interval: 3000 }}
        infinite
        className="p-3"
        prevArrow={prevArrow}
        nextArrow={nextArrow}
        dots={{ className: "text-black" }}
      >
        <div className="pb-10">
          <div className="flex flex-col justify-evenly items-center md:flex-row gap-6 md:gap-0">
            <div className="text-xl">
              <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
                <div>
                  <img
                    className="h-20 w-20 object-contain"
                    src="/assets/home/3d.png"
                    alt=""
                  />
                </div>
                <div>
                  <h1>✨ 3D Tasarım</h1>
                  <p>
                    Sanatı ve teknolojiyi birleştirerek benzersiz görsel
                    deneyimler yaratıyoruz.
                  </p>
                </div>
              </div>
            </div>
            <div className="text-xl">
              <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
                <div>
                  <img
                    className="h-20 w-20 object-contain"
                    src="/assets/home/web.png"
                    alt=""
                  />
                </div>
                <div>
                  <h1>🌐 Web Tasarım</h1>
                  <p>
                    İnternetin gücünü kullanarak şirketinizin dijital varlığını
                    güçlendiriyoruz.
                  </p>
                </div>
              </div>
            </div>
            <div className="text-xl">
              <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
                <div>
                  <img
                    className="h-20 w-20 object-contain"
                    src="/assets/home/robot.png"
                    alt=""
                  />
                </div>
                <div>
                  <h1>🤖 Robotik Kodlama</h1>
                  <p>Geleceğin dilini konuşan robotlarla tanışın.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* İkinci sayfa */}
        <div>
          <div className="flex flex-col justify-evenly items-center md:flex-row gap-6 md:gap-0">
            <div className="text-xl">
              <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
                <div>
                  <img
                    className="h-20 w-20 object-contain"
                    src="/assets/home/mobile.png"
                    alt=""
                  />
                </div>
                <div>
                  <h1>📱Mobil Uygulama</h1>
                  <p>
                    Kullanıcı dostu mobil uygulamalarla müşterilerinize her an
                    her yerden ulaşın.
                  </p>
                </div>
              </div>
            </div>
            <div className="text-xl">
              <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
                <div>
                  <img
                    className="h-20 w-20 object-contain"
                    src="/assets/home/social.png"
                    alt=""
                  />
                </div>
                <div>
                  <h1>🌐 Sosyal Medya Yönetimi</h1>
                  <p>Markanızı sosyal medyada etkileşimli ve çekici kılın.</p>
                </div>
              </div>
            </div>
            <div className="text-xl">
              <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
                <div>
                  <img
                    className="h-20 w-20 object-contain"
                    src="/assets/home/corp.png"
                    alt=""
                  />
                </div>
                <div>
                  <h1>🏢 Kurumsal Yazılım</h1>
                  <p>
                    İş süreçlerinizi optimize eden özel kurumsal yazılımlar
                    geliştiriyoruz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Üçüncü sayfa */}
        <div>
            <div className="flex justify-evenly items-center flex-col md:flex-row gap-6 md:gap-0">
            <div className="text-xl">
            <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
              <div>
                <img
                  className="h-20 w-20 object-contain"
                  src="/assets/home/drawing.png"
                  alt=""
                />
              </div>
              <div>
                <h1>📐 Teknik Çizim</h1>
                <p>Detaylarda mükemmelliği yakalayın.</p>
              </div>
            </div>
          </div>
          <div className="text-xl">
            <div className="flex md:flex-row flex-col text-center md:text-start items-center gap-2">
              <div>
                <img
                  className="h-20 w-20 object-contain"
                  src="/assets/home/image.png"
                  alt=""
                />
              </div>
              <div>
                <h1>🖼 Görüntü İşleme</h1>
                <p>
                  İleri teknolojiyle görsel verileri en etkili şekilde işleyin.
                </p>
              </div>
            </div>
          </div>

            </div>
        </div>
      </Carousel>
    </div>
  );
};

export default YourComponent;
