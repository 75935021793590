import React from "react";
import { NavLink } from "react-router-dom";
const MobilUygulama = () => {
  return (
    <div className="container mx-auto flex flex-col items-center justify-center mt-10">
      <div id="serviceHead" className="flex max-w-[1000px]">
        <div
          id="serviceHeadInfo"
          className="max-w-[700px] flex flex-col items-center text-center justify-center p-5 md:p-0"
        >
          <h1 id="servideTitle" className="text-xl font-bold">
            Mobil Uygulama
          </h1>
          <p>
            Biz, mobil uygulama geliştirme alanında teknolojiye yön veren bir
            ekip olarak, kullanıcılarımıza değer katmak ve eğitimden
            etkinliklere kadar geniş bir yelpazede çözümler sunmak için
            çalışıyoruz. Siz de bizimle birlikte, teknolojinin geleceğini
            şekillendiren bu yolculuğa katılın!
          </p>
          <NavLink
            className={"mt-5 bg-sky-700 px-2 py-1 rounded text-white"}
            to="/contact"
          >
            İletişime Geç
          </NavLink>
        </div>
        <div id="serviceImage" className="hidden lg:block">
          <img
            src="https://apiss.mithatsarsu.com/img/mobileapp-ad6c9a85-47e0-44a0-b1ba-7fc2b3e9eb5b.png"
            alt="mobiluygulama"
          />
        </div>
      </div>
      <div id="serviceBody">
        <div id="servicePresentation">
          <div
            id="servicePresentationHead"
            className="text-center bg-blue-900 py-2 md:rounded text-white text-lg font-semibold mt-10 mb-10"
          >
            <h1>Neden IPSS Mobil Uygulama?</h1>
          </div>
          <div
            id="servicePresentationBody"
            className="flex flex-col md:flex-row p-5 md:p-0 gap-5 mb-5"
          >
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center gap-2">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/interactive-995ea9cb-0f21-4e36-b16e-780e004f33a2.png"
                  alt="detail"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  Etkileşim ve Kolay Kullanım
                </h1>
                <p>
                  Mobil uygulamalar, kullanıcılarımıza etkileşimli ve kolay
                  kullanımlı bir deneyim sunar. IPSS projelerinde bu prensipleri
                  benimseyerek, kullanıcıların uygulamalarımızı rahatça
                  kullanmalarını sağlar.
                </p>
              </div>
            </div>
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/operation-d80060d0-96fd-4f42-a993-cbbe7a594243.png"
                  alt="engineer"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  İnovasyon ve Teknoloji Entegrasyonu
                </h1>
                <p>
                  Mobil uygulama projelerimiz, en son teknoloji trendlerini
                  takip eder ve inovatif çözümleri içerir. Böylece, IPSS
                  kullanıcıları teknolojinin en güncel olanaklarından
                  faydalanabilirler.
                </p>
              </div>
            </div>
            <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
              <div className="servicePresentationItemImage w-1/2">
                <img
                  className="w-20 h-20 object-contain"
                  src="https://apiss.mithatsarsu.com/img/hand-27fdbab2-e930-4252-aac3-fb5b0cc9efeb.png"
                  alt="engineer"
                />
              </div>
              <div className="servicePresentationItemContent">
                <h1 className="text-lg font-medium">
                  Özelleştirilebilir ve Genişletilebilir Çözümler
                </h1>
                <p>
                  IPSS'in her bir mobil uygulama projesi, özelleştirilebilir ve
                  genişletilebilir çözümleri içerir. Bu, ihtiyaca özel
                  uygulamalar geliştirme yeteneğimizi vurgular.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="serviceProjects" className="mb-20">
          <div id="projectPresentation">
            <div
              id="projectPresentationHead"
              className="text-center bg-blue-900 py-2 rounded text-white text-lg font-semibold mt-10 mb-10"
            >
              <h1 id="projectPresentationTitle">
                IPSS'in Geşliştirdiği Mobil Uygulamalar
              </h1>
            </div>
            <div
              id="projectPresentationBody"
              className="flex gap-16 p-5 md:p-0"
            >
              <div
                id="projectPresentationsImage"
                className="w-full hidden md:block"
              >
                <img
                  src="https://apiss.mithatsarsu.com/img/undraw_mobile_development_re_wwsn-9ed517ba-ef43-4bc2-b6e1-aa68dc8a30b6.svg"
                  alt="project"
                />
              </div>
              <div
                id="projectPresentationContents"
                className="flex flex-col gap-6"
              >
                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                  <div className="servicePresentationItemImage hidden md:block w-1/3">
                    <img
                      className="w-20 h-20"
                      src="https://apiss.mithatsarsu.com/img/education-450f514b-db32-4490-914b-f130937d22c9.png"
                      alt="engineer"
                    />
                  </div>
                  <div className="servicePresentationItemContent">
                    <h1 className="text-lg font-medium">
                      Eğitim Destek Uygulaması
                    </h1>
                    <p>
                      IPSS eğitim sürecini desteklemek amacıyla özel bir mobil
                      uygulama geliştirmiştir. Uygulama, interaktif içerikler,
                      ders materyalleri ve canlı etkileşimli öğrenme modüllerini
                      içerir. Bu proje, öğrencilerimizin eğitimde daha etkili
                      olmalarına yardımcı olmayı hedefler.
                    </p>
                  </div>
                </div>
                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                  <div className="servicePresentationItemImage hidden md:block w-1/3">
                    <img
                      className="w-20 h-20"
                      src="https://apiss.mithatsarsu.com/img/dictionary-5ec03834-2a6c-4cdb-8a60-963ae6217c7e.png"
                      alt="engineer"
                    />
                  </div>
                  <div className="servicePresentationItemContent">
                    <h1 className="text-lg font-medium">Sözlük Uygulaması</h1>
                    <p>
                      IPSS dil zenginliğinizi artırmak ve anlam karmaşasını
                      ortadan kaldırmak için tasarlanmış yenilikçi bir mobil
                      sözlük uygulaması geliştirdi. Hem öğrenciler hem de
                      profesyoneller için ideal olan bu uygulama, dil
                      becerilerinizi geliştirmek ve her gün yeni kelimeler
                      öğrenmek için size rehberlik eder.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilUygulama;
