import React from "react";
import { NavLink } from "react-router-dom";
const SosyalMedya = () => {
    return (
        <div className="container mx-auto flex flex-col items-center justify-center mt-10">
            <div id="serviceHead" className="flex max-w-[1000px]">
                <div
                    id="serviceHeadInfo"
                    className="max-w-[700px] flex flex-col items-center text-center justify-center p-5 md:p-0"
                >
                    <h1 id="servideTitle" className="text-xl font-bold">
                        Sosyal Medya Yönetimi
                    </h1>
                    <p>
                    Sosyal medya dünyası her geçen gün heyecan verici bir şekilde gelişiyor ve biz, bu değişimin öncüleri olmaya kararlıyız! Yeniliklere açık, teknolojiye olan tutkumuzu her adımda hissedebileceğiniz dinamik bir ekibiz. Yaratıcılığı ve enerjiyi ön planda tutan bir yaklaşımla, sosyal medyanın gücünü en üst seviyede kullanmak için çalışıyoruz.
                    </p>
                    <NavLink className={"mt-5 bg-sky-700 px-2 py-1 rounded text-white"} to="/contact">İletişime Geç</NavLink>
                </div>
                <div id="serviceImage" className="hidden lg:block">
                    <img
                        src="https://cdn-icons-png.flaticon.com/256/4545/4545111.png"
                        alt="teknikcizim"
                    />
                </div>
            </div>
            <div id="serviceBody">
                <div id="servicePresentation">
                    <div
                        id="servicePresentationHead"
                        className="text-center bg-blue-900 py-2 md:rounded text-white text-lg font-semibold mt-10 mb-10"
                    >
                        <h1>Sosyal Medya Yönetiminde Neden IPSS?</h1>
                    </div>
                    <div id="servicePresentationBody" className="flex flex-col md:flex-row p-5 md:p-0 gap-5 mb-5">
                        <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center gap-2">
                            <div className="servicePresentationItemImage w-1/2">
                                <img
                                    className="w-20 h-20 object-contain"
                                    src="https://cdn-icons-png.flaticon.com/128/983/983860.png"
                                    alt="detail"
                                />
                            </div>
                            <div className="servicePresentationItemContent">
                                <h1 className="text-lg font-medium">
                                    Hedef Kitle Analizi
                                </h1>
                                <p>
                                    Hedef kitlenizin demografik özelliklerini, ilgi alanlarını ve davranışlarını belirleyerek, içerik stratejinizi onlara göre şekillendiriyoruz.
                                </p>
                            </div>
                        </div>
                        <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
                            <div className="servicePresentationItemImage w-1/2">
                                <img
                                    className="w-20 h-20 object-contain"
                                    src="https://cdn-icons-png.flaticon.com/128/2098/2098276.png"
                                    alt="engineer"
                                />
                            </div>
                            <div className="servicePresentationItemContent">
                                <h1 className="text-lg font-medium">
                                    İçerik Planlama ve Oluşturma
                                </h1>
                                <p>
                                    Belirlediğiniz hedef kitleye uygun, ilgi çekici ve değerli içerikler oluşturuyoruz. Görsel ve metin içeriklerinin yanı sıra video ve canlı yayınları da içeren çeşitli formatlar kullanıyoruz.
                                </p>
                            </div>
                        </div>
                        <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
                            <div className="servicePresentationItemImage w-1/2">
                                <img
                                    className="w-20 h-20 object-contain"
                                    src="https://cdn-icons-png.flaticon.com/128/4149/4149727.png"
                                    alt="engineer"
                                />
                            </div>
                            <div className="servicePresentationItemContent">
                                <h1 className="text-lg font-medium">
                                    Günlük Yönetim ve İçerik Paylaşımı
                                </h1>
                                <p>
                                    Sosyal medya hesaplarınızı düzenli olarak güncelleyip, takipçilerinizle etkileşimde bulunuyoruz. Planlı bir içerik paylaşımı stratejisi oluşturuyoruz.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="serviceProjects" className="mb-20">
                    <div id="projectPresentation">
                        <div
                            id="projectPresentationHead"
                            className="text-center bg-blue-900 py-2 rounded text-white text-lg font-semibold mt-10 mb-10"
                        >
                            <h1 id="projectPresentationTitle">
                                IPSS Sosyal Medya Hesaplarınızla Nasıl İlgilenir
                            </h1>
                        </div>
                        <div id="projectPresentationBody" className="flex gap-16 p-5 md:p-0">
                            <div id="projectPresentationsImage" className="w-full hidden md:block">
                                <img
                                    src="https://apiss.mithatsarsu.com/img/socialmedia-1618d42d-052d-4e36-a67a-c57fbd119159.svg"
                                    alt="project"
                                />
                            </div>
                            <div id="projectPresentationContents" className="flex flex-col gap-6">
                                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                                    <div className="servicePresentationItemImage hidden md:block w-1/3">
                                        <img
                                            className="w-20 h-20"
                                            src="https://cdn-icons-png.flaticon.com/128/610/610413.png"
                                            alt="engineer"
                                        />
                                    </div>
                                    <div className="servicePresentationItemContent">
                                        <h1 className="text-lg font-medium">
                                            Takipçi İletişimi ve İlgilenme
                                        </h1>
                                        <p>
                                            Takipçilerinizin yorumlarına ve mesajlarına zamanında ve ilgiyle yanıt vererek, etkileşimi arttırıyoruz. Müşteri memnuniyetini sağlamak için takipçilerinizle aktif iletişim halinde oluyoruz.
                                        </p>
                                    </div>
                                </div>
                                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                                    <div className="servicePresentationItemImage hidden md:block w-1/3">
                                        <img
                                            className="w-20 h-20"
                                            src="https://cdn-icons-png.flaticon.com/128/2318/2318736.png"
                                            alt="engineer"
                                        />
                                    </div>
                                    <div className="servicePresentationItemContent">
                                        <h1 className="text-lg font-medium">
                                        Analiz ve Geri Bildirimlerin Değerlendirilmesi
                                        </h1>
                                        <p>
                                        Sosyal medya performansınızı düzenli olarak analiz ediyoruz. Paylaşımlarınızın etkileşimini, büyümeyi ve dönüşüm oranlarını izleyerek stratejinizi sürekli olarak iyileştiriyoruz.
                                        </p>
                                    </div>
                                </div>
                                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                                    <div className="servicePresentationItemImage hidden md:block w-1/3">
                                        <img
                                            className="w-20 h-20"
                                            src="https://cdn-icons-png.flaticon.com/128/10493/10493370.png"
                                            alt="engineer"
                                        />
                                    </div>
                                    <div className="servicePresentationItemContent">
                                        <h1 className="text-lg font-medium">
                                        Reklam Yönetimi ve Optimizasyonu
                                        </h1>
                                        <p>
                                        Sosyal medya reklamlarıyla hedef kitlenize daha etkili bir şekilde ulaştırıyoruz. Reklam bütçenizi doğru bir şekilde yönetip, reklam kampanyalarınızı sürekli olarak optimize ediyoruz.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SosyalMedya;
