import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
const Services = () => {
  const handleNavLinkClick = () => {
    // Sayfa geçişini gerçekleştirirken scroll pozisyonunu sıfırla
    window.scrollTo(0, 0);
  };
  const service = useSelector((state) => state.service);
  return (
    <div id="projects" className="services mb-20">
      <h2 className="bg-blue-900 poppins-regular poppins-regular p-2 w-full text-white text-center text-4xl font-semibold mb-10 tracking-wider">
        HİZMETLERİMİZ
      </h2>
      <div className="services-container container mx-auto p-5 md:p-0 grid-cols-1 lg:grid-cols-2 grid xl:grid-cols-4 items-center text-center gap-10">
        {service?.services?.map((item) => {
          return (
            <NavLink onClick={handleNavLinkClick} key={item._id} to={`/s/${item.SlugUrl}`} className="services-container-box border border-gray-400 hover:shadow-xl">
              <img width={12} height={12} loading="lazy" className="text-center w-12 h-12 object-contain" alt="robocode" src={item.ImageUrl} />
              <h3 className="text-center">{item.ServiceTitle}</h3>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default Services;
