import React from 'react'
import Banner from '../components/Home/Banner'
import Services from '../components/Home/Services'
import Blog from '../components/Home/Blog'
//import Partners from '../components/Home/Partners'
import useDocumentTitle from '../hooks/useDocumentTitle'
import Applications from '../components/Home/Applications'
import Projects from '../components/Home/Projects'
import Introduction from '../components/Home/Introduction'
const Home = () => {
  useDocumentTitle("IPSS - Ana Sayfa")

  return (
    <div>
        <Banner />
        <Services />
        <Projects title="Projeler"/>
        <Introduction />
        
        {/* <Applications /> */}
        {/* <Blog /> */}
    </div>
  )
}

export default Home