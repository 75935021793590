import React from "react";
import { NavLink } from "react-router-dom";
const Otonom = () => {
    return (
        <div className="container mx-auto flex flex-col items-center justify-center mt-10">
            <div id="serviceHead" className="flex max-w-[1000px]">
                <div
                    id="serviceHeadInfo"
                    className="max-w-[700px] flex flex-col items-center text-center justify-center p-5 md:p-0"
                >
                    <h1 id="servideTitle" className="text-xl font-bold">
                        Otonom Sürüş ve Robotik Kodlama
                    </h1>
                    <p>
                        Otonom, geleceğin teknolojisiyle bugünü buluşturuyoruz. Yenilikçi yaklaşımlarımız ve sektördeki uzmanlığımızla, otonom teknolojilerin potansiyelini en üst düzeye çıkarıyoruz. Müşterilerimize özelleştirilmiş çözümler sunarak, güvenli, verimli ve sürdürülebilir otonom sistemlerin gelişimine liderlik ediyoruz. İleri teknolojiyle donatılmış ekiplerimiz ve endüstriyel ortaklarımızla birlikte, dönüşümünüzü yönlendiriyoruz. Geleceği bugün keşfedin, Otonom ile birlikte adım atın.
                    </p>
                    <NavLink className={"mt-5 bg-sky-700 px-2 py-1 rounded text-white"} to="/contact">İletişime Geç</NavLink>
                </div>
                <div id="serviceImage" className="hidden lg:block">
                    <img
                        src="https://cdn-icons-png.flaticon.com/256/7396/7396437.png"
                        alt="teknikcizim"
                    />
                </div>
            </div>
            <div id="serviceBody">
                <div id="servicePresentation">
                    <div
                        id="servicePresentationHead"
                        className="text-center bg-blue-900 py-2 md:rounded text-white text-lg font-semibold mt-10 mb-10"
                    >
                        <h1>Otonom Sürüş ve Robotik Kodlamada Neden IPSS?</h1>
                    </div>
                    <div id="servicePresentationBody" className="flex flex-col md:flex-row p-5 md:p-0 gap-5 mb-5">
                        <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center gap-2">
                            <div className="servicePresentationItemImage w-1/2">
                                <img
                                    className="w-20 h-20 object-contain"
                                    src="https://cdn-icons-png.flaticon.com/128/7571/7571822.png"
                                    alt="detail"
                                />
                            </div>
                            <div className="servicePresentationItemContent">
                                <h1 className="text-lg font-medium">
                                    Otonom Araçlar ve Sistemlerde Verimlilik Artışı
                                </h1>
                                <p>
                                    Müşterilerimize sunduğumuz otonom araçlar ve sistemlerde verimlilik artışı hizmetleriyle, işletmelerin taşımacılık ve lojistik süreçlerini optimize etmeye odaklanıyoruz. Yapay zeka entegrasyonlarıyla desteklenen otonom araçlarımız, güvenlik standartlarını yükseltirken aynı zamanda enerji verimliliğini artırıyor, işletmelerin maliyetlerini düşürüyoruz.
                                </p>
                            </div>
                        </div>
                        <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
                            <div className="servicePresentationItemImage w-1/2">
                                <img
                                    className="w-20 h-20 object-contain"
                                    src="https://cdn-icons-png.flaticon.com/128/12350/12350508.png"
                                    alt="engineer"
                                />
                            </div>
                            <div className="servicePresentationItemContent">
                                <h1 className="text-lg font-medium">
                                    Özelleştirilmiş Otonom Taşıma Çözümleri
                                </h1>
                                <p>
                                    Her işletmenin özel ihtiyaçlarına uygun çözümler sunarak, otonom taşıma alanında öncüyüz. Lojistik süreçlerinizi iyileştirmek ve operasyonel verimliliğinizi artırmak için özelleştirilmiş otonom taşıma çözümleri geliştiriyoruz. Bu sayede, işletmelerin rekabet gücünü artırmalarına ve maliyetlerini optimize etmelerine yardımcı oluyoruz.
                                </p>
                            </div>
                        </div>
                        <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
                            <div className="servicePresentationItemImage w-1/2">
                                <img
                                    className="w-20 h-20 object-contain"
                                    src="https://cdn-icons-png.flaticon.com/128/4025/4025600.png"
                                    alt="engineer"
                                />
                            </div>
                            <div className="servicePresentationItemContent">
                                <h1 className="text-lg font-medium">
                                    Yenilikçi Otonom Ulaşım Araçları
                                </h1>
                                <p>
                                    Müşterilerimize en son teknolojiye dayalı yenilikçi otonom ulaşım araçları sunarak, taşıma ve ulaşım alanında çığır açıyoruz. Sürekli AR-GE çalışmalarıyla geliştirdiğimiz bu araçlar, kullanıcılarına daha güvenli, verimli ve konforlu bir yolculuk deneyimi sunuyor.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="serviceProjects" className="mb-20">
                    <div id="projectPresentation">
                        <div
                            id="projectPresentationHead"
                            className="text-center bg-blue-900 py-2 rounded text-white text-lg font-semibold mt-10 mb-10"
                        >
                            <h1 id="projectPresentationTitle">
                                IPSS'in Yürüttüğü Projelerden Bazıları
                            </h1>
                        </div>
                        <div id="projectPresentationBody" className="flex gap-16 p-5 md:p-0">
                            <div id="projectPresentationsImage" className="w-full hidden md:block">
                                <img
                                    src="https://apiss.mithatsarsu.com/img/undraw_electric_car_b-7-hl-1ac079df-f794-45fe-8774-e2dea7245b04.svg"
                                    alt="project"
                                />
                            </div>
                            <div id="projectPresentationContents" className="flex flex-col gap-6">
                                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                                    <div className="servicePresentationItemImage hidden md:block w-1/3">
                                        <img
                                            className="w-20 h-20"
                                            src="https://cdn-icons-png.flaticon.com/128/3463/3463455.png"
                                            alt="engineer"
                                        />
                                    </div>
                                    <div className="servicePresentationItemContent">
                                        <h1 className="text-lg font-medium">
                                            Otonom Araçlarda Enerji Verimliliği
                                        </h1>
                                        <p>
                                            İşletmelerin çevresel etkilerini azaltmak ve enerji maliyetlerini düşürmek amacıyla otonom araçlarda enerji verimliliğini artırmaya odaklanıyoruz. Akıllı enerji yönetimi sistemleri ve çevre dostu tasarım özellikleriyle donattığımız araçlarımız, daha az enerji tüketirken çevreye de daha az zarar veriyor.
                                        </p>
                                    </div>
                                </div>
                                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                                    <div className="servicePresentationItemImage hidden md:block w-1/3">
                                        <img
                                            className="w-20 h-20"
                                            src="https://cdn-icons-png.flaticon.com/128/4350/4350974.png"
                                            alt="engineer"
                                        />
                                    </div>
                                    <div className="servicePresentationItemContent">
                                        <h1 className="text-lg font-medium">
                                            Güvenlik Odaklı Otonom Sürüş Sistemleri
                                        </h1>
                                        <p>
                                            Müşterilerimizin güvenliğini her zaman ön planda tutarak, otonom araçlarımızda en güvenli sürüş sistemlerini kullanıyoruz. Görüntü işleme tabanlı güvenlik sistemleriyle donatılan araçlarımız, çevresel tehlikeleri tespit ederek kazaları önler ve kullanıcıların güvenliğini sağlar.
                                        </p>
                                    </div>
                                </div>
                                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                                    <div className="servicePresentationItemImage hidden md:block w-1/3">
                                        <img
                                            className="w-20 h-20"
                                            src="https://cdn-icons-png.flaticon.com/128/1534/1534214.png"
                                            alt="engineer"
                                        />
                                    </div>
                                    <div className="servicePresentationItemContent">
                                        <h1 className="text-lg font-medium">
                                            Otonom Araçlarda Kalite Kontrolü ve Bakım Yönetimi
                                        </h1>
                                        <p>
                                            İşletmelerin araçlarının uzun ömürlü olmasını sağlamak için, otonom araçlarımızda kalite kontrolü ve bakım yönetimi süreçlerini optimize ediyoruz. Görüntü işleme teknolojisiyle desteklenen bu süreçler sayesinde, araçlarınızın performansını düzenli olarak izleyerek, gerektiğinde bakım işlemlerini planlıyoruz ve işletmelerin operasyonel verimliliğini artırıyoruz.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Otonom;
