import React from "react";
import { NavLink } from "react-router-dom";
const Goruntu = () => {
    return (
        <div className="container mx-auto flex flex-col items-center justify-center mt-10">
            <div id="serviceHead" className="flex max-w-[1000px]">
                <div
                    id="serviceHeadInfo"
                    className="max-w-[700px] flex flex-col items-center text-center justify-center p-5 md:p-0"
                >
                    <h1 id="servideTitle" className="text-xl font-bold">
                        Görüntü ve Ses İşleme
                    </h1>
                    <p>
                        İleri teknoloji altyapımızla, karmaşık görüntü verilerini analiz ederek müşterilerimize özelleştirilmiş çözümler sunuyoruz. Endüstriyel ve akademik alanlarda kanıtlanmış başarılarımızla, verimliliği artırın ve rekabet avantajı elde edin. Geleceğinize şekil vermek için IPSS'i tercih edin.
                    </p>
                    <NavLink className={"mt-5 bg-sky-700 px-2 py-1 rounded text-white"} to="/contact">İletişime Geç</NavLink>
                </div>
                <div id="serviceImage" className="hidden lg:block">
                    <img
                        src="https://cdn-icons-png.flaticon.com/256/4359/4359835.png"
                        alt="teknikcizim"
                    />
                </div>
            </div>
            <div id="serviceBody">
                <div id="servicePresentation">
                    <div
                        id="servicePresentationHead"
                        className="text-center bg-blue-900 py-2 md:rounded text-white text-lg font-semibold mt-10 mb-10"
                    >
                        <h1>Görüntü ve Ses İşlemede Neden IPSS?</h1>
                    </div>
                    <div id="servicePresentationBody" className="flex flex-col md:flex-row p-5 md:p-0 gap-5 mb-5">
                        <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center gap-2">
                            <div className="servicePresentationItemImage w-1/2">
                                <img
                                    className="w-20 h-20 object-contain"
                                    src="https://cdn-icons-png.flaticon.com/128/11630/11630987.png"
                                    alt="detail"
                                />
                            </div>
                            <div className="servicePresentationItemContent">
                                <h1 className="text-lg font-medium">
                                    Optimize Sistem Desteği
                                </h1>
                                <p>
                                    İşletmenizin verimliliğini artırırken maliyetleri düşürmenize ve performansı artırmanıza yardımcı oluyoruz. Gelişmiş görüntü işleme ve yapay zeka algoritmalarıyla, iş süreçlerinizi optimize eder ve daha etkili kararlar almanıza olanak sağlıyoruz.
                                </p>
                            </div>
                        </div>
                        <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
                            <div className="servicePresentationItemImage w-1/2">
                                <img
                                    className="w-20 h-20 object-contain"
                                    src="https://cdn-icons-png.flaticon.com/128/14602/14602492.png"
                                    alt="engineer"
                                />
                            </div>
                            <div className="servicePresentationItemContent">
                                <h1 className="text-lg font-medium">
                                    Özelleştirilmiş Teknoloji Entegrasyonları
                                </h1>
                                <p>
                                    Her işletme benzersizdir ve farklı gereksinimlere sahiptir. Biz de bu ölçüde ihtiyaçlarınızı analiz ederek özelleştirilmiş çözümler sunuyoruz. Görüntü işleme ve yapay zeka teknolojilerini, işletmenizin spesifik gereksinimlerine uyacak şekilde adapte ediyoruz.
                                </p>
                            </div>
                        </div>
                        <div className="servicePresentationItem p-3 bg-orange-200 bg-opacity-20 rounded flex items-center">
                            <div className="servicePresentationItemImage w-1/2">
                                <img
                                    className="w-20 h-20 object-contain"
                                    src="https://cdn-icons-png.flaticon.com/128/3058/3058444.png"
                                    alt="engineer"
                                />
                            </div>
                            <div className="servicePresentationItemContent">
                                <h1 className="text-lg font-medium">
                                    Yenilikçi Çözümler
                                </h1>
                                <p>
                                    Ekibimiz, sürekli olarak AR-GE çalışmaları yaparak yenilikçi çözümler geliştirmektedir. Teknolojiye hakimiyetimiz sayesinde, en son trendleri takip ederek müşterilerimize avantaj sağlıyoruz.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="serviceProjects" className="mb-20">
                    <div id="projectPresentation">
                        <div
                            id="projectPresentationHead"
                            className="text-center bg-blue-900 py-2 rounded text-white text-lg font-semibold mt-10 mb-10"
                        >
                            <h1 id="projectPresentationTitle">
                                IPSS'in Yürüttüğü Projelerden Bazıları
                            </h1>
                        </div>
                        <div id="projectPresentationBody" className="flex gap-16 p-5 md:p-0">
                            <div id="projectPresentationsImage" className="w-full hidden md:block">
                                <img
                                    src="https://apiss.mithatsarsu.com/img/goruntuisleme-bd602daa-c788-4adf-acb8-0dcce17bccea.svg"
                                    alt="project"
                                />
                            </div>
                            <div id="projectPresentationContents" className="flex flex-col gap-6">
                                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                                    <div className="servicePresentationItemImage hidden md:block w-1/3">
                                        <img
                                            className="w-20 h-20"
                                            src="https://cdn-icons-png.flaticon.com/128/8055/8055576.png"
                                            alt="engineer"
                                        />
                                    </div>
                                    <div className="servicePresentationItemContent">
                                        <h1 className="text-lg font-medium">
                                            Yapaya Zeka Destekli Atık Yönetimi ve Enerji Verimliliğin Takibi
                                        </h1>
                                        <p>
                                            IPSS tarafından geliştirilecek atık yönetimi sistemleri, atıkların sınıflandırılması ve geri dönüşüm süreçlerinin yönetilmesinde önemli bir rol oynayacak. Bu sistemler aynı zamanda binalarda enerji verimliliğini artırmak için kullanılabilecek, aydınlatma ve iklimlendirme sistemlerini optimize ederek enerji tasarrufu sağlayacak.
                                        </p>
                                    </div>
                                </div>
                                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                                    <div className="servicePresentationItemImage hidden md:block w-1/3">
                                        <img
                                            className="w-20 h-20"
                                            src="https://cdn-icons-png.flaticon.com/128/13063/13063346.png"
                                            alt="engineer"
                                        />
                                    </div>
                                    <div className="servicePresentationItemContent">
                                        <h1 className="text-lg font-medium">
                                            Görüntü İşleme Tabanlı Takip Sistemleri
                                        </h1>
                                        <p>
                                            IPSS, şehirlerdeki trafik yoğunluğunu analiz edip optimize ederek zaman ve yakıt tasarrufu sağlayacak. Aynı zamanda güvenlik alanında da kullanılabilen görüntü işleme tabanlı drone tespit ve takip sistemleri, trafik kazalarını önlemek ve güvenliği artırmak için etkili bir çözüm sunacak.

                                        </p>
                                    </div>
                                </div>
                                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                                    <div className="servicePresentationItemImage hidden md:block w-1/3">
                                        <img
                                            className="w-20 h-20"
                                            src="https://cdn-icons-png.flaticon.com/128/1534/1534214.png"
                                            alt="engineer"
                                        />
                                    </div>
                                    <div className="servicePresentationItemContent">
                                        <h1 className="text-lg font-medium">
                                            Görsel Kalite Kontrolü
                                        </h1>
                                        <p>
                                            Endüstriyel üretim süreçlerinde kullanılan görüntü işleme destekli robotlar, ürün kalitesini kontrol etmek ve üretim verimliliğini artırmak için önemli bir rol oynamaktadır. IPSS, kalite kontrol sistemleri ile entegre olarak çalışarak üretim hatasını minimize edecek ve ürün kalitesini artıracaklar.
                                        </p>
                                    </div>
                                </div>
                                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                                    <div className="servicePresentationItemImage hidden md:block w-1/3">
                                        <img
                                            className="w-20 h-20"
                                            src="https://cdn-icons-png.flaticon.com/128/11357/11357126.png"
                                            alt="engineer"
                                        />
                                    </div>
                                    <div className="servicePresentationItemContent">
                                        <h1 className="text-lg font-medium">

                                            Yüksek Verimlilikte Tarım Bilgisayarlı Görü Kontrol Yazılımları

                                        </h1>
                                        <p>
                                            IPSS, tarım süreçlerini otomatikleştirerek verimliliği artıracak. Ayrıca, hayvanların izlenmesi ve takibi için geliştirilen görüntü işleme tabanlı sistemler, çiftliklerdeki hayvan sağlığını izlemek ve yönetmek için önemli bir araç olacak.

                                        </p>
                                    </div>
                                </div>
                                <div className="servicePresentationItem p-3 bg-sky-200 bg-opacity-20 rounded flex items-center">
                                    <div className="servicePresentationItemImage hidden md:block w-1/3">
                                        <img
                                            className="w-20 h-20"
                                            src="https://cdn-icons-png.flaticon.com/128/13637/13637220.png"
                                            alt="engineer"
                                        />
                                    </div>
                                    <div className="servicePresentationItemContent">
                                        <h1 className="text-lg font-medium">

                                            Görüntü İşleme Tabanlı Güvenlik Sistemler


                                        </h1>
                                        <p>
                                            Görüntü işleme tabanlı güvenlik sistemleri, işletmelerin güvenliğini artırmak için etkili bir çözüm sunacak. Aynı teknoloji, mağazalar veya etkinlikler gibi yoğun alanlarda insan sayımı ve takibi için de kullanılabilir, böylece işletmeler güvenliklerini sağlarken aynı zamanda ziyaretçi akışını da izleyebilirler.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Goruntu;
